<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card custom-card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h3 class="mb-0"><i class="fa fa-edit"></i> แก้ไขข้อมูลประเภทสถานที่ตั้ง </h3>
            </b-col>
          </b-row>
        </div>

        <div class="card-body">
          <validation-observer v-slot="{handleSubmit}" ref="formValidator">
            <b-form @submit.prevent="handleSubmit(editHandler)">
              <form-location-type :item="item"/>
              <div class="float-right d-flex">
                <span><router-link :to="'/location-type'" class="nav-link text-underline"> กลับ</router-link></span>
                <!-- <b-button variant="secondary" @click="onBackHandler()"><i class="fa fa-arrow-left"></i> กลับ</b-button> -->
                <b-button type="submit" variant="primary" class="style_btn" v-if="permission.actionData.flag_update"><i class="fa fa-check"></i> บันทึก</b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formLocationType from './form.location_type';
export default {
  name: 'location_type-view-dialog-location_type-edit',
  data () {
    return {
      permission:{
        appSlug: 'location-type',
        actionData: {}
      },
      id: "",
      item: {
        id: '',
        name: ''
      }
    }
  },
  methods: {
    async editHandler () {
      const result = await this.HttpServices.putData(`/ab_location_type/${this.item.id}`, this.item);
      if(result&&result.status.code=="200"){
        this.AlertUtils.alertCallback('success', `บันทึกสำเร็จ`, ()=>{
          this.$router.push('/location-type');
        });
      }else{
        this.AlertUtils.alert('warning', result.status.message);
      }
    },
    onBackHandler(){
      this.$router.push("/location-type");
    },

    async getItem(){
      const result = await this.HttpServices.getData(`/ab_location_type/${this.id}`);
      if(result&&result.status.code=="200"){
        this.item = result.data;
      }else{
        this.AlertUtils.alertCallback('warning', `ไม่พบข้อมูลผู้ผลิต`, ()=>{
          this.$router.push('/location-type');
        });
      }
    }
  },
  components: {
    formLocationType
  },
  async mounted() {
    await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
    this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
    this.id = await this.SessionStorageUtils.getSession("page-location-type");
    await this.getItem();
  }
}
</script>
